import React from "react";

export function dynamicBackgroundImageSet(dynamicPath: string, variant: string | null): string {
  const attrs = dynamicBackgroundStyles(dynamicPath, variant);
  return attrs.map(s => stringifyStyles(s)).join("")
}

function dynamicBackgroundStyles(dynamicPath: string, variant: string | null) : { 'background-image': string }[] {
  const imageryUrl = new URL(dynamicPath);
  const fallback = {
    'background-image': `url("${dynamicPath}");`
  }
  const imageSet = {
    'background-image':
      `image-set(
        ${imageSetEntryFor(imageryUrl, variant, 'webp', '2x')},
        ${imageSetEntryFor(imageryUrl, variant, 'webp', '1x')}
      );`.replaceAll(/[\r\n]/g, "")
  };

  // Including the `type(...)` attribute in a `url` value causes historical WebKit browsers
  // to reject the entire `background-image` attribute, so in order to allow for type
  // discrimination in Chromium or Gecko (or sufficiently bleeding-edge WebKit), we add the
  // prefixed block, _prior_ to the general form.
  const webkitImageSet = {
    'background-image':
      `-webkit-image-set(
        ${webkitImageSetEntryFor(imageryUrl, variant, '2x')},
        ${webkitImageSetEntryFor(imageryUrl, variant, '1x')}
      );`.replaceAll(/[\r\n]/g, "")
  };

  return [fallback, webkitImageSet, imageSet];
}

interface StyledProperties extends React.CSSProperties {
  '--webp-2x-path': string;
  '--webp-1x-path': string;
  '--jpeg-2x-path': string;
}

export function dynamicBackgroundProperties(dynamicPath: string, variant: string | null) : StyledProperties {
  const imageryUrl = new URL(dynamicPath);

  return {
    '--webp-2x-path': `url('${urlFor(imageryUrl, variant, 'webp', '2x')}')`,
    '--webp-1x-path': `url('${urlFor(imageryUrl, variant, 'webp', '1x')}')`,
    '--jpeg-2x-path': `url('${urlFor(imageryUrl, variant, 'jpeg', '2x')}')`,
  }
}

function stringifyStyles(styleDict: Record<string, string>) {
  return Object.entries(styleDict).reduce((acc, [style, value]) => { return acc + `${style}: ${value}` }, "")
}

export function literalBackgroundImageSet(webp2xPath: string, webp1xPath: string, jpeg2xPath: string): string {
  const fallback = `background-image: url("${jpeg2xPath};");`;
  const imageSet = `background-image: image-set
    url("${webp2xPath}") 2x,
    url("${webp1xPath}") 1x);`.replaceAll(/[\r\n]/g, "");

  const webkitImageSet = `background-image: -webkit-image-set(
    url("${webp2xPath}") 2x,
    url("${webp1xPath}") 1x);`.replaceAll(/[\r\n]/g, "");

  return (fallback + webkitImageSet + imageSet);
}

function imageSetEntryFor(basePath: URL, variant: string | null, format: 'webp' | 'jpeg', size: '1x' | '2x'): string {
  return `url('${urlFor(basePath, variant, format, size)}') ${size}`
}

function webkitImageSetEntryFor(basePath: URL, variant: string | null, size: '1x' | '2x'): string {
  return `url('${urlFor(basePath, variant, 'webp', size)}') ${size}`
}

function urlFor(basePath: URL, variant: string | null, format: 'webp' | 'jpeg', size: '1x' | '2x'): string {
  const search = basePath.searchParams;

  if (variant != null) {
    search.set("variant", `${variant}_${format}_${size}`);
  } else {
    search.set("variant", `${format}_${size}`);
  }

  return basePath.toString();
}
